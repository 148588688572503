import React from 'react'
import SplashImage from "../components/SplashImage";
import "../App.css"


const Home = () => {
    return (
        <div id="home">
        {/* <Navigation /> */}
        <SplashImage />
        </div>
    )
}

export default Home
